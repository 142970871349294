import { Button } from '@mui/material';
import React from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { SxProps, Theme } from '@mui/system';
import { style } from '@/styles/globals';

interface ChangeCalendarBtnProps {
  type: 'left' | 'right';
  onClick: () => void;
  sx?: SxProps<Theme>;
}

const ChangeCalendarBtn: React.FC<ChangeCalendarBtnProps> = ({
  type,
  onClick,
  sx,
}) => (
  <Button
    sx={{
      margin: 0,
      padding: 0,
      minWidth: 'auto',
      ...style.text.content.regular,
      color: style.color.black2,
      ...sx,
    }}
    onClick={onClick}
  >
    {type === 'left' ? (
      <KeyboardArrowLeftIcon
        sx={{
          fontSize: '30px',
          background: style.color.white2,
        }}
        htmlColor={style.color.black2}
      />
    ) : (
      <KeyboardArrowRightIcon
        sx={{
          fontSize: '30px',

          background: style.color.white2,
        }}
        htmlColor={style.color.black2}
      />
    )}
  </Button>
);

export default ChangeCalendarBtn;
