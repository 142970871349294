import { AreaResult } from '@/slices/area/apis';

export const transformAreaData = (areas: AreaResult[]) => {
  let dataResponse = [];
  dataResponse = areas.map((area) => ({
    id: area?.id || '',
    location: area?.name || '',
    fullname: area?.full_name || '',
    country: 'Việt Nam',
    highlight: area?.highlight || '',
    type: area?.type || 0,
    stateName: area?.state_name || '',
    stateId: area?.state_id || '',
    locationToken: area?.name_token || '',
    train_stations: (area as any)?.train_stations || [],
    airports: area?.airports
      ? area.airports.map((airport) => ({
        id: airport.id || '',
        nameVn: airport.properties.AirportName_Vi || '',
        nameEn: airport.properties.AirportName_En || '',
        distance: airport.distance,
        airportCode: airport.properties.IATA_FAA || '',
        highlight: airport.highlight || airport.properties.AirportName_Vi || '',
      }))
      : [],
  }));
  return dataResponse;
};
