import { ILangObj } from '@/slices/goyoloSeo/sliceType';
import { style } from '@/styles/globals';
import { Typography } from '@mui/material';
import { uniqueId } from 'lodash';
import React from 'react';

const ListDateInWeek = ({ lang }: { lang?: ILangObj }) => {
  const renderDay = (dayArr = ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN']) => {
    const DayComponent = dayArr.map((day) => {
      const isWeekend = /T7|CN/i.test(day);
      return (
        <Typography
          variant="contentBold"
          key={uniqueId()}
          style={{ color: isWeekend ? style.color.red3 : style.color.black4 }}
        >
          {day}
        </Typography>
      );
    });
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        {DayComponent}
      </div>
    );
  };
  return <>{renderDay(lang?.global?.dayOfWeek || ['T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'CN'])}</>;
};

export default ListDateInWeek;
