/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable prefer-arrow-callback */
import CustomCalendar from '@/components/desktop/CustomCalendar';
import ListDateInWeek from '@/components/ListDateInWeek';
import { style } from '@/styles/globals';
import { useAppSelector } from '@/utils/hook';
import { pr } from '@/utils/pxToRem';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { add, differenceInCalendarMonths, format } from 'date-fns';
import { useMemo, useState } from 'react';
import ChangeCalendarBtn from '../ChangeCalendarBtn';
import type { ICalendarListProps } from './type.d';

const CalendarList = ({
  isNotShowPrice,
  dayPriceObj,
  limit = 2,
  disableChangeMonth,
  selectedDate,
  type,
  setSelectedDate,
  isDisableRoundTrip,
  isLunarYearMode,
  isVcms,
  lang,
}: ICalendarListProps) => {
  // const selectedDate = useAppSelector((state) => state.calendar.selectedDate);
  const isShowCalendarVietNam = useAppSelector(
    (state) => state.calendar.showCalendarVietNam,
  );
  const chooseReturn = useAppSelector((state) => state.calendar.chooseReturn);
  const dataList = Array(limit).fill({});
  const diffMonthToNow = useMemo(
    () => differenceInCalendarMonths(selectedDate.depart, new Date()),
    [],
  );
  const [monthIndex, setMonthIndex] = useState(diffMonthToNow);
  const CalendarComp = useMemo(
    () => (
      <Stack
        direction="row"
        spacing={isDisableRoundTrip ? '40px' : '8px'}
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
        }}
      >
        {dataList.map((_, idx) => {
          const nextMonth = add(new Date(), {
            months: idx + monthIndex,
          });
          const GroupTitleBar = () => (
            <Stack
              sx={{ textAlign: 'center' }}
              spacing={pr`22px`}
            >
              <Box sx={{ position: 'relative' }}>
                {!disableChangeMonth && (
                  <>
                    {idx === 0 ? (
                      <ChangeCalendarBtn
                        type="left"
                        sx={{
                          position: 'absolute',
                          top: '-1px',
                          zIndex: 10,
                          left: '16px',
                        }}
                        onClick={() => {
                          if (
                            differenceInCalendarMonths(nextMonth, new Date()) === 0
                          ) { return; }
                          setMonthIndex(monthIndex - 1);
                        }}
                      />
                    ) : (
                      <ChangeCalendarBtn
                        type="right"
                        sx={{
                          position: 'absolute',
                          top: '-1px',
                          zIndex: 10,
                          right: '16px',
                        }}
                        onClick={() => {
                          if (differenceInCalendarMonths(nextMonth, new Date()) > 12) return;
                          setMonthIndex(monthIndex + 1);
                        }}
                      />
                    )}
                  </>
                )}
                <Box />
                <Typography
                  variant="body2"
                  color={style.color.black4}
                  fontWeight="bold"
                >
                  {lang?.lang === 'en' ? format(new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 1), 'MMMM yyyy') : `${lang?.global?.month || 'Tháng'} ${
                    nextMonth.getMonth() + 1
                  }, ${nextMonth.getFullYear()}`}

                </Typography>
              </Box>

              <ListDateInWeek lang={lang} />
            </Stack>
          );
          return (
            <Stack
              sx={{ width: limit === 1 ? '100%' : '375px' }}
              key={idx.toString() + monthIndex}
            >
              <GroupTitleBar />
              <CustomCalendar
                isNotShowPrice={isNotShowPrice}
                isDisableRoundTrip={isDisableRoundTrip}
                type={type}
                highlightToday={idx === 0}
                monthAdded={idx + monthIndex}
                dayPriceObj={dayPriceObj}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                isVcms={isVcms}
                lang={lang}
              />
            </Stack>
          );
        })}
      </Stack>
    ),
    [chooseReturn, selectedDate, monthIndex],
  );

  return <>{CalendarComp}</>;
};

export default CalendarList;
