import { style } from '@/styles/globals';
import { useAppSelector } from '@/utils/hook';
import { pr } from '@/utils/pxToRem';
import React, {
  useCallback, useEffect,
} from 'react';
import Select, {
  ActionMeta, components, InputActionMeta,
  SingleValue,
} from 'react-select';
import type { CustomSelectProps } from './index.d';

const IndicatorSeparator = () => <></>;

const DropdownIndicator = () => <></>;

const Input = (props: any) => (
  <components.Input
    {...props}
    maxLength={50}
  />
);

const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  Control,
  onChange,
  onInputChange,
  onExit,
  isSearchable,
  placeholder,
}) => {
  const currentArea = useAppSelector((state) => state.area.currentArea);

  const handleInputChange = useCallback(

    (newValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta.action === 'input-change') {
        if (onInputChange) {
          onInputChange(newValue, actionMeta);
        }
      }
    },
    [],
  );
  const handleOnChange = (
    newValue: SingleValue<any>,
    actionMeta: ActionMeta<any>,
  ) => {
    if (onChange) {
      onChange(newValue, actionMeta);
    }
  };

  useEffect(() => () => {
    onExit();
  }, []);

  useEffect(() => {
    if (currentArea) {
      if (onInputChange) {
        onInputChange(currentArea);
      }
    }
  }, [currentArea]);

  return (
    <div style={{ position: 'relative' }}>
      <Select
        components={{
          Input,
          Control,
          IndicatorSeparator,
          DropdownIndicator,
        }}
        isSearchable={isSearchable}
        closeMenuOnSelect={false}
        styles={{
          clearIndicator: (base, state): any => ({
            ...base,
            cursor: 'pointer',
            display: state.isFocused ? 'block' : 'none',
            color: style.color.black2,
          }),
          valueContainer: (base: any) => ({
            ...base,
            flexWrap: 'nowrap',
            overflow: 'unset',
            padding: '0px 0px 0px 0px !important',
          }),
          placeholder: (base) => ({
            ...base,
            color: `${style.color.black2} !important`,
            fontSize: '0.875rem',
          }),
          singleValue: (base) => ({
            ...base,
            ...style.text.content.bold,
            color: style.color.black2,
          }),
          input: (base) => ({
            ...base,
            ...style.text.content.regular,
            color: style.color.black2,
            fontWeight: 500,
          }),
          control: (base: any) => ({
            ...base,
            flexWrap: 'nowrap',
            overflow: 'auto',
            marginBottom: pr`16px`,
            minWidth: '241px',
            border: '1px solid #BAC7D5',
            boxSizing: 'border-box',
            borderRadius: '3px',
            maxHeight: '44px',
            overflowY: 'hidden',
            cursor: 'pointer',
          }),
        }}
        // inputValue={localInputValue}
        value={value}
        placeholder={placeholder}
        onChange={handleOnChange}
        onInputChange={handleInputChange}
        menuIsOpen={false}
      />
      {/* {localInputValue ? (
        <CloseIcon
          style={{
            zIndex: 99,
            position: 'absolute',
            right: '12px',
            top: '12px',
            width: '16px',
            height: '16px',
            background: style.color.black4,
            borderRadius: '50px',
            color: '#ffffff',
          }}
          onClick={() => {
            onClearText();
          }}
        />
      ) : null} */}
    </div>
  );
};
export default CustomSelect;
