/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable prefer-arrow-callback */
import {
  CalendarPicker,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Badge, Typography, Stack } from '@mui/material';
import { Box, styled } from '@mui/system';
import {
  add,
  compareAsc,
  differenceInDays,
  differenceInMonths,
  format,
  formatISO,
  getMonth,
  startOfMonth,
  sub,
} from 'date-fns';
import { vi } from 'date-fns/locale';
import { memo, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';
// import { setReturnDate, setSelectedDate } from '../../slices/calendar/calendarSlice';
import { useAppSelector } from '@/utils/hook';
import { calendarVN } from '@/utils/calendarVietNam';
import { ICustomCalendarPickerProps } from './type';
import { style } from '@/styles/globals';
import { matchPriceOrDash } from '@/utils/matchPriceOrDash';
import { useRouter } from 'next/router';
import { useBoolean } from '@/utils/hooks';
import RedirectAlert from '@/components/RedirectAlert';
// import { setSelectedDate } from '../../slices/calendar/calendarSlice';

const StyledBox = styled(Box)`
  display: flex;
  alignitems: flex-end;

  .MuiCalendarPicker-root {
    width: 100%;
  }
  .MuiPickersDay-root.Mui-disabled {
    color: ${style.color.black6} !important;
  }
  .MuiCalendarPicker-root > div:first-of-type {
    display: none;
  }
  .PrivatePickersFadeTransitionGroup-root
    > div:first-of-type
    > div:first-of-type {
    display: none;
  }
  .MuiCalendarPicker-viewTransitionContainer
    > div:first-of-type
    > div:first-of-type {
    display: none;
  }
  .MuiCalendarPicker-root.StyledCalendarPicker
    > div:nth-of-type(2)
    > div:first-of-type {
    display: none;
  }
  .MuiCalendarPicker-root.StyledCalendarPicker
    > div:nth-of-type(2)
    > div:first-of-type {
    display: none;
  }
  .MuiCalendarPicker-root.StyledCalendarPicker
    > div:nth-of-type(2)
    > div[style*="opacity: 1"] {
    display: inherit;
  }
  .PrivatePickersSlideTransition-root {
    min-height: 0;
  }
  .PrivatePickersSlideTransition-root > div:first-of-type {
    position: relative;
  }
  .PrivatePickersSlideTransition-root > div:first-of-type > div {
    justify-content: space-around;
    margin: 0;
  }
  .MuiBadge-root > button,
  .MuiPickersDay-root {
    flex: 1 1 1px;
    height: 54px;
    margin: 0;
  }
`;

const StyledBoxVcms = styled(Box)`
  display: flex;
  alignitems: flex-end;

  .MuiCalendarPicker-root {
    width: 100%;
  }
  .MuiPickersDay-root.Mui-disabled {
    color: ${style.color.black6} !important;
  }
  .MuiCalendarPicker-root > div:first-of-type {
    display: none;
  }
  .PrivatePickersFadeTransitionGroup-root
    > div:first-of-type
    > div:first-of-type {
    display: none;
  }
  .MuiCalendarPicker-viewTransitionContainer
    > div:first-of-type
    > div:first-of-type {
    display: none;
  }
  .MuiCalendarPicker-root.StyledCalendarPicker
    > div:nth-of-type(2)
    > div:first-of-type {
    display: none;
  }
  .MuiCalendarPicker-root.StyledCalendarPicker
    > div:nth-of-type(2)
    > div:first-of-type {
      display: block !important;
  }
  .MuiCalendarPicker-root.StyledCalendarPicker
    > div:nth-of-type(2)
    > div[style*="opacity: 1"] {
    display: inherit;
  }
  .PrivatePickersSlideTransition-root {
    min-height: 0;
  }
  .PrivatePickersSlideTransition-root > div:first-of-type {
    position: relative;
  }
  .PrivatePickersSlideTransition-root > div:first-of-type > div {
    justify-content: space-around;
    margin: 0;
  }
  .MuiBadge-root > button,
  .MuiPickersDay-root {
    flex: 1 1 1px;
    height: 54px;
    margin: 0;
  }
`;

const FullWidthStyledBox = styled(Box)({
  width: '100%',
});
const StyledButton = styled(Badge)({
  flexGrow: 1,
  zIndex: 0,
  padding: 0,
  minWidth: 0,
});
const SmallBadge = styled(Badge)({
  fontSize: 0,
  position: 'absolute',
  top: '0',
  zIndex: 2,
});
const StyledFromToContainer = styled(Box)({
  position: 'absolute',
});

const CustomBadge = ({ children, isRight, ...props }: any) => (
  <SmallBadge
    componentsProps={{
      badge: {
        style: {
          padding: '0',
          background: 'rgba(255, 255, 255, 0.2)',
          color: 'white',
          alignItems: 'flex-end',
          alignContent: 'flex-end',
          borderRadius: isRight ? '0 0 0 30%' : '0 0 30% 0',
          // paddingBottom: isRight ? 0 : '2px',
          height: 15,
          top: isRight ? 6 : 1,
          right: isRight ? 3 : 1,
          textAlign: 'center',
        },
      },
    }}
    {...props}
    badgeContent={<span style={{ fontSize: '10px' }}>{children}</span>}
  />
);

const Hidden = Box;
const toDateIsoString = (date: Date) => formatISO(date, { representation: 'date' });
const CustomCalendarPicker = ({
  highlightToday,
  monthAdded,
  dayPriceObj,
  selectedDate,
  type,
  setSelectedDate,
  showCalendarLunar,
  showStyleLunar,
  isDisableRoundTrip,
  isNotShowPrice,
  isVcms,
  lang,
}: ICustomCalendarPickerProps) => {
  // const selectedDate = useAppSelector((state) => state.calendar.selectedDate);

  // const dispatch = useDispatch();
  const { query } = useRouter();
  const isReturn = query?.type === 'return';

  const today = new Date();
  const addedMonthDate = add(today, { months: monthAdded });
  const selectedDepartDate = selectedDate.depart;
  const selectedReturnDate = selectedDate.return ? selectedDate.return : null;
  let selectedDepartDateInMonth = null as Date | null;
  let selectedReturnDateInMonth = null as Date | null;
  let isBetweenDepartAndReturn = false;

  const departMonth = getMonth(selectedDepartDate) + 1;
  const returnMonth = selectedReturnDate
    ? getMonth(selectedReturnDate) + 1
    : null;
  const addedMonth = getMonth(addedMonthDate) + 1;

  if (departMonth && departMonth === addedMonth) {
    selectedDepartDateInMonth = selectedDepartDate;
  }
  if (returnMonth && returnMonth === addedMonth) {
    selectedReturnDateInMonth = selectedReturnDate;
  }

  const selectedDepartDateMonthStart = startOfMonth(selectedDepartDate);
  const selectedReturnDateMonthStart = selectedReturnDate ? startOfMonth(selectedReturnDate) : null;
  const addedMonthDateMonthStart = startOfMonth(addedMonthDate);

  if (
    (selectedDepartDateMonthStart
      && selectedReturnDateMonthStart
      && differenceInMonths(addedMonthDateMonthStart, selectedDepartDateMonthStart) >= 0
      && differenceInMonths(selectedReturnDateMonthStart, addedMonthDateMonthStart) >= 0)
    || (selectedDepartDate
      && !selectedReturnDate
      && getMonth(selectedDepartDate) === getMonth(addedMonthDate))
  ) {
    isBetweenDepartAndReturn = true;
  }

  const minPriceObj = useMemo(() => {
    const obj: any = {};

    if (!dayPriceObj) return obj;

    Object.keys(dayPriceObj).map((key) => {
      const monthKey = `${key.split('-')[0]}-${key.split('-')[1]}`;
      if (monthKey in obj) {
        obj[monthKey] = Math.min(obj[monthKey], dayPriceObj[key]);
      } else {
        obj[monthKey] = dayPriceObj[key];
      }
    });
    return obj;
  }, [dayPriceObj]);

  const CalendarDateMemo = memo(function CalendarDateComponent({
    day,
    pickersDayProps = {} as any,
    dateNo,
    month,
    year,
    isMinPrice,
  }: {
    day: Date;
    dateNo: number;
    month: number;
    year: number;
    pickersDayProps: PickersDayProps<Date>;
    isMinPrice: boolean;
  }) {
    const shouldDisabled = useMemo(() => {
      const diff = differenceInDays(new Date(day), new Date());
      if (type === 'return') {
        const returnDiff = differenceInDays(
          new Date(day),
          new Date(selectedDate.depart),
        );
        return returnDiff < 0;
      }
      return diff < 0;
    }, [selectedDate, day]);
    const [param, setParam] = useState<any>({});
    const [open, setOpen] = useBoolean(false);
    const [vcms, setVcms] = useState<boolean>(!!isVcms);
    const chooseReturn = useAppSelector((state) => state.calendar.chooseReturn);
    const flightData = useAppSelector((state) => state.flightData);
    const isShowCalendarVietNam = useAppSelector(
      (state) => state.calendar.showCalendarVietNam,
    );

    const { outsideCurrentMonth } = pickersDayProps;
    let matchDepartDate = false;
    let matchReturnDate = false;
    let matchInBetweenDate = false;
    const dateString: string = format(day, 'EEEEEE');
    const isDayInWeekend = /Sa|Su/i.test(dateString);
    const isAfterToday = compareAsc(day, sub(today, { days: 1 })) > 0;
    const currDayIsoString = toDateIsoString(day);

    if (selectedDepartDateInMonth) {
      matchDepartDate = toDateIsoString(selectedDepartDateInMonth) === currDayIsoString;
    }
    if (selectedReturnDateInMonth) {
      matchReturnDate = toDateIsoString(selectedReturnDateInMonth) === currDayIsoString;
    }

    let dayPrice: string;
    if (flightData?.minFareToday && matchDepartDate && !isReturn) {
      dayPrice = matchPriceOrDash(Math.round(flightData.minFareToday / 1000));
    } else if (flightData?.minFareToday && matchReturnDate && isReturn) {
      dayPrice = matchPriceOrDash(Math.round(flightData.minFareToday / 1000));
    } else if (dayPriceObj) {
      const date = format(new Date(day), 'yyyy-MM-dd');
      dayPrice = !isNotShowPrice ? matchPriceOrDash(!dayPriceObj[date] ? 0 : Math.round((dayPriceObj[date]) / 1000)) : ''; // if no value, shot not display 0
    }

    if (
      selectedDepartDate
      && selectedReturnDate
      && !matchReturnDate
      && compareAsc(selectedDepartDate, day) < 0
      && compareAsc(day, selectedReturnDate) < 0
    ) {
      matchInBetweenDate = true;
    }

    const departBadge = !isDisableRoundTrip && chooseReturn && !outsideCurrentMonth && matchDepartDate ? (
      <CustomBadge
        style={{
          width: '10px',
          top: 5,
          left: lang?.lang === 'en' ? 10 : 0,
          textAlight: 'center',
        }}
      >
        {lang?.SearchBar?.flightsTab?.calendarFrom || 'Đi'}
      </CustomBadge>
    ) : null;
    const returnBadge = !isDisableRoundTrip && chooseReturn && !outsideCurrentMonth && matchReturnDate ? (
      <CustomBadge
        style={{
          left: lang?.lang === 'en' ? 10 : 0,
          width: '10px',
          textAlight: 'center',
        }}
        isRight
      >
        {lang?.SearchBar?.flightsTab?.calendarReturn || 'Về'}
      </CustomBadge>
    ) : null;

    const dayVN = calendarVN.fromGregorian(year, month, dateNo) as any;
    const isDayInNewYear = showStyleLunar
      && ((dayVN.month === 12 && dayVN.day >= 25)
        || (dayVN.month === 1 && dayVN.day <= 4));
    const setSelectedDateVcms = (paramData: any) => {
      setParam(paramData);
      setSelectedDate(paramData);
    };
    const DateComponent = ({
      isShowDateCalendarVietNam,
    }: {
      isShowDateCalendarVietNam: boolean;
    }) => (
      <PickersDay
        {...pickersDayProps as any}
        disabled={shouldDisabled}
        // selected={matchDepartDate || matchReturnDate}
        onDayFocus={() => { }}
        onDaySelect={() => {
          let setFn = setSelectedDate;
          if (vcms) {
            setFn = setSelectedDateVcms;
          }
          if (day) {
            if (type === 'depart') {
              if (selectedDate.return) {
                const diff = differenceInDays(selectedDate.return, day);
                if (diff < 0) {
                  setFn({
                    depart: day,
                    return: add(day, { days: 2 }),
                  });
                  return;
                }
              }
              setFn({
                depart: day,
                return: null,
              });
            } else {
              setFn({
                return: day,
              });
            }
          }
        }}
        focusRipple
        centerRipple
        style={{
          display: 'block',
          borderRadius: '3px',
          ...(isAfterToday && isDayInWeekend && { color: '#CF1322' }),
          ...(isDayInNewYear && {
            background: 'white',
            color: `${style.color.black6} !important`,
          }),
          ...(matchInBetweenDate ? { background: style.color.blue4 } : {}),
          ...(matchDepartDate || matchReturnDate
            ? {
              background: style.color.blue3,
              color: 'white',
            }
            : {}),

          ...(matchReturnDate
            ? {
              borderRadius: '0px 8px 8px 0px',
            }
            : {}),
          ...(matchDepartDate
            ? (selectedDate.return === null || matchReturnDate) ? {
              borderRadius: '8px',
            } : {
              borderRadius: '8px 0px 0px 8px',
            }
            : {}),

        }}
        sx={{
          border: `1.5px solid ${style.color.white}`,
          '&:hover': {
            border: `1.5px solid ${style.color.blue2}`,
            backgroundColor: `${style.color.white}`,
          },
        }}
      >
        {departBadge && (
          <StyledFromToContainer
            style={{
              left: 1,
              top: 18,
            }}
          >
            ||
          </StyledFromToContainer>
        )}
        {returnBadge && (
          <StyledFromToContainer
            style={{
              right: 1,
              top: 18,
            }}
          >
            ||
          </StyledFromToContainer>
        )}
        <Stack
          sx={{
            p: '8px',
            boxSizing: 'border-box',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography
            component="span"
            sx={{
              position: isShowDateCalendarVietNam ? 'absolute' : 'static',
              color: 'inherit',
              fontWeight: 'bold',
            }}
            variant="body2"
          >
            {day.getDate()}
          </Typography>
          {!isNotShowPrice && (
            <span
              style={{
                fontSize: '10px',
                position: 'absolute',
                width: '100%',
                left: 0,
                bottom: isShowDateCalendarVietNam ? '5%' : '20%',
                fontWeight: '500',
                // eslint-disable-next-line no-nested-ternary
                color:
                  matchDepartDate || matchReturnDate
                    ? 'white'
                    : dayPrice && isMinPrice
                      ? style.color.product2
                      : style.color.black3,
                display: dayPriceObj ? 'block' : 'none',
              }}
            >
              {isAfterToday && (dayPrice || '')}
            </span>
          )}
          {isShowDateCalendarVietNam && (
            <span
              style={{
                position: 'absolute',
                top: '22px',
                right: '10px',
                fontSize: '10px',
                // color: 'rgba(255, 255, 255, 0.8)',
                ...(isDayInNewYear && { color: '#CF1322' }),
              }}
            >
              {dayVN.day}
              {dayVN.day === 1 && `/ ${dayVN.month}`}
            </span>
          )}
        </Stack>
      </PickersDay>
    );

    return (
      <StyledButton
        style={{
          zIndex: matchDepartDate || matchReturnDate ? 1 : 3,
          background: 'white',
        }}
        key={uniqueId()}
      >
        {departBadge}
        <DateComponent
          isShowDateCalendarVietNam={isShowCalendarVietNam}
        />
        <RedirectAlert
          open={open}
          setOpen={setOpen}
          params={param}
          onConfirm={setSelectedDate}
        />
        {returnBadge}
      </StyledButton>
    );
  });

  const CalendarPickerMemo = useMemo(
    () => (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={vi}
      >
        <CalendarPicker
          className="StyledCalendarPicker"
          views={['day']}
          openTo="day"
          date={null}
          disablePast
          components={{
            LeftArrowButton: Hidden,
            LeftArrowIcon: Hidden,
            RightArrowButton: Hidden,
            RightArrowIcon: Hidden,
            SwitchViewButton: Hidden,
            SwitchViewIcon: Hidden,
          }}
          defaultCalendarMonth={addedMonthDate}
          minDate={new Date()}
          disableHighlightToday={!highlightToday}
          onChange={() => { }}
          renderDay={(day: any, selectedDays: any, pickersDayProps: any) => {
            const date = format(day, 'yyyy-MM-dd');
            const monthKey = `${date.split('-')[0]}-${date.split('-')[1]}`;
            return (
              <CalendarDateMemo
                key={uniqueId()}
                day={day}
                isMinPrice={
                  dayPriceObj
                    ? minPriceObj[monthKey] === dayPriceObj[date]
                    : false
                }
                pickersDayProps={pickersDayProps}
                dateNo={day.getDate()}
                month={day.getMonth() + 1}
                year={day.getFullYear()}
              />
            );
          }}
        />
      </LocalizationProvider>
    ),
    [
      isBetweenDepartAndReturn,
      isBetweenDepartAndReturn
        ? `${selectedDepartDate}-${selectedReturnDate}`
        : '',
      JSON.stringify(dayPriceObj),
    ],
  );

  return CalendarPickerMemo;
};

type ICustomCalendarProps = ICustomCalendarPickerProps;
const CustomCalendar = ({
  highlightToday = false,
  monthAdded = 0,
  dayPriceObj,
  selectedDate,
  setSelectedDate,
  type,
  showCalendarLunar,
  showStyleLunar,
  isDisableRoundTrip,
  isNotShowPrice,
  isVcms,
  lang,
}: ICustomCalendarProps) => (
  <FullWidthStyledBox>
    {isVcms ? (
      <StyledBoxVcms>
        <CustomCalendarPicker
          isNotShowPrice={isNotShowPrice}
          type={type}
          isDisableRoundTrip={isDisableRoundTrip}
          highlightToday={highlightToday}
          monthAdded={monthAdded}
          dayPriceObj={dayPriceObj}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          showCalendarLunar={showCalendarLunar}
          showStyleLunar={showStyleLunar}
          isVcms={isVcms}
          lang={lang}
        />
      </StyledBoxVcms>
    ) : (
      <StyledBox>
        <CustomCalendarPicker
          isNotShowPrice={isNotShowPrice}
          type={type}
          isDisableRoundTrip={isDisableRoundTrip}
          highlightToday={highlightToday}
          monthAdded={monthAdded}
          dayPriceObj={dayPriceObj}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          showCalendarLunar={showCalendarLunar}
          showStyleLunar={showStyleLunar}
          isVcms={isVcms}
          lang={lang}
        />
      </StyledBox>
    ) }
    {/* <StyledDevider variant="fullWidth" /> */}
  </FullWidthStyledBox>
);

export default CustomCalendar;
