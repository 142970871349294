import PopularArea from '@/components/PopularArea';
import RecentArea from '@/components/RecentArea';
import PopperInput from '@/containers/Desktop/PopperInput';
import { LocationSearchType } from '@/containers/SearchFlightTab/SearchDialog/index.d';
import SearchFlightResultItem from '@/containers/SearchFlightTab/SearchFlightResultItem';
import type { IFlightResultItem } from '@/containers/SearchFlightTab/SearchFlightResultItem/index.d';
import { useLazyGetAreaByNameQuery } from '@/slices/area/apis/areaApi';
import { style } from '@/styles/globals';
import { clearAnchorPopperInput } from '@/utils/eventEmitter';
import { useAppSelector, useAppDispatch } from '@/utils/hook';
import { pr } from '@/utils/pxToRem';
import { transformAreaData } from '@/utils/transformAreaData';
import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import { debounce, uniqueId } from 'lodash';
import { NoResultSearchIcon } from 'public/icons';
import React, { useRef, useState } from 'react';
import { addArea } from '@/slices/area/slices/areaSlice';
import { addHomePoints } from '@/slices/area/slices/searchFlightFormSlice';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';

interface SelectAreaProps {
  type: LocationSearchType;
  lang?: ILangObj;
  point?: any,
  isFirstTime?: boolean;
  switchUpdateSecondTime?: any;
  vehicleType?: 'bus' | 'flight' | 'train';

}

const SelectArea: React.FC<SelectAreaProps> = ({
  type, lang, point, isFirstTime, switchUpdateSecondTime, vehicleType,
}) => {
  const dispatch = useAppDispatch();

  const [isInputEmpty, setIsInputEmpty] = useState(true);
  const [getAreaByName, { data, isFetching }] = useLazyGetAreaByNameQuery();

  const isInputEmptyRef = useRef(true);
  const debounceGetArea = useRef(debounce(getAreaByName, 100));
  const dataMapping = useRef<IFlightResultItem[]>([]);

  const { startPointHome, toPointHome } = useAppSelector(
    (state) => state.searchFlightForm,
  );
  const areaPoint = type === LocationSearchType.FROM ? startPointHome : toPointHome;
  if (dataMapping.current) {
    dataMapping.current = data && (!isInputEmptyRef.current || areaPoint)
      ? transformAreaData(data)
      : [];
  }

  const [hoverIndex, setHoverIndex] = React.useState<any>(0);

  React.useEffect(() => {
    if (data) setHoverIndex(0);
  }, [data]);

  const refEl = React.useRef<any>();
  return (
    <PopperInput
      isFirstTime={isFirstTime}
      point={point}
      lang={lang}
      title={type === LocationSearchType.FROM ? (lang?.SearchBar?.flightsTab?.from || 'Nơi đi') : (lang?.SearchBar?.flightsTab?.to || 'Nơi đến')}
      vehicleType={vehicleType}
      setIsInputEmpty={setIsInputEmpty}
      type={type}
      debounceGetArea={debounceGetArea}
      isInputEmptyRef={isInputEmptyRef}
      onClickOutside={() => {
        if (!isInputEmptyRef.current && !isFetching && dataMapping.current) {
          dispatch(addArea({
            area: dataMapping.current?.[0],
          }));
          dispatch(addHomePoints({
            data: dataMapping.current?.[0],
            type,
          }));
        }
      }}
    >
      <ContentContainer ref={refEl}>
        {isInputEmpty && (
          <>
            <RecentArea
              vehicleType={vehicleType}
              lang={lang}
              isDesktop
              type={type}
              setClose={clearAnchorPopperInput}
            />
            <PopularArea
              vehicleType={vehicleType}
              lang={lang}
              isDesktop
              type={type}
              setClose={clearAnchorPopperInput}
            />
          </>
        )}
        {!isInputEmptyRef.current
          && !isFetching
          && dataMapping.current.map((item, index) => (
            <SearchFlightResultItem
              type={type}
              isInputEmptyRef={isInputEmptyRef}
              setClose={clearAnchorPopperInput}
              isSearchResult
              setIsInputEmpty={setIsInputEmpty}
              key={uniqueId()}
              data={item}
              index={index}
              hoverIndex={hoverIndex}
              onMouseEnter={setHoverIndex}
              lang={lang}
            />
          ))}
        {isFetching && (
          <StyleLoading>
            <Box
              sx={{
                display: 'flex',
                pb: pr`16px`,
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
            <Typography variant="body2">
              {lang?.SearchBar?.flightsTab?.waiting || 'Vui lòng đợi. Tìm kiếm của bạn đang được xử lý'}
            </Typography>
          </StyleLoading>
        )}
        {data?.length === 0 && !isInputEmptyRef.current && !isFetching && (
          <StyleLoading>
            <Box
              sx={{
                display: 'flex',
                pb: pr`16px`,
              }}
            >
              <NoResultSearchIcon />
            </Box>
            <Typography
              variant="body2"
              sx={{
                color: style.color.black2,
                fontWeight: '700',
              }}
            >
              {lang?.SearchBar?.flightsTab?.notFound || 'Rất tiếc Goyolo không tìm thấy địa điểm này'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: style.color.black2,
                fontSize: '12px',
                lineHeight: '16px',
                mt: '8px',
              }}
            >
              {lang?.SearchBar?.flightsTab?.notFoundHelp || 'Bạn vui lòng nhập tỉnh/thành phố, quận/huyện, sân bay để tìm.'}
            </Typography>
          </StyleLoading>
        )}
      </ContentContainer>
    </PopperInput>
  );
};

const StyleLoading = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50%;
  align-items: center;
  padding: 0 18px
  `;

export const ContentContainer = styled(Box)`
  padding-bottom: 16px;
  padding-top: 16px;
  border: 1px solid #e8edf1;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgb(7 65 57 / 15%);
  border-radius: 6px;
  background: white;
  max-height: 472px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export default SelectArea;
